import React from 'react'
import { dismissBlock } from '../AccountSetupItems/AccountSetupUtility'
import { MYLO_AD_BLOCK } from '../TodoSection/Todo.const'
import AdvertisementCard from './AdvertisementCard'
import MyloAdImage from '../../../assets/images/MyloAdImage.png'

const MyloAd = ({
    reload,
}: {
    reload: () => void
}) => {
    const meetingLink = `https://choosemylo.com/1800accountant?campaignid=24ACT007&utm_source=24ACT007&utm_medium=siteplacement&utm_campaign=Mylo`
    
    return (
        <AdvertisementCard
            title="Get Business Insurance with the Best Coverage + Price!"
            subtitle="Shield your business from surprises like lawsuits, damaged property, and lost income that can bankrupt a growing company with help from Mylo."
            buttonText="Start My Quote"
            cardImage={MyloAdImage}
            onDismissClick={() => {
                reload()
                dismissBlock(MYLO_AD_BLOCK)
            }}
            buttonClick={() => {
                window.open(meetingLink, '_blank');
            }}
        />
    )
}

export default MyloAd