import { AccountSetupKeyType } from '../../../models/dashboardModel'
export const COMPLETION_PERCENTAGE = 100
export const TAX_ADVISORY = 'TAX_ADVISORY'
export const BASIC_BOOKKEEPING_PERMISSION = 'BASIC_BOOKKEEPING'
export const PAYROLL_PERMISSION = 'PAYROLL'
export const ESTIMATED_TAXES_PERMISSION = 'ESTIMATED_TAXES'

export const ATTEND_DISCOVERY_CALL = 'attend_discovery_call'
export const ATTEND_ADVISORY_CALL = 'attend_advisory_call'
export const MERCHANT_ACCOUNT_SETUP = 'merchant_account_setup'
export const CONNECT_BANK_ACCOUNT = 'connected_bank_account'
export const BANK_ACCOUNT_CREATED = 'bank_account_created'

export const DISCOVERY_APPOINTMENT = 'Discovery'
export const ADVISORY_APPOINTMENT = 'Advisory'
export const TODO_CONNECT_BANK = 'todo_connect_bank'
export const TODO_MERCHANT_SETUP = 'todo_merchant_setup'

export const ACCOUNT_SETUP_KEYS: AccountSetupKeyType[]  = [
    'completed_discovery_appointment', 
    'completed_advisory_appointment', 
    'connected_bank_account',
    'merchant_account_setup'
]

export const FREE_ACCOUNT_SETUP_KEYS: AccountSetupKeyType[] = [
    "connected_bank_account", 
    "merchant_account_setup"
]
export const DISMISSED_BLOCK_STORAGE_KEY = 'dismissed_dashboard_blocks'
export const PAYROLL_AD_BLOCK = 'payroll_ad_block'
export const BOOKKEEPING_AD_BLOCK = 'bookkeeping_ad_block'
export const DOWNLOAD_APP_AD_BLOCK = 'downloadapp_ad_block'
export const MERCHANT_SETUP_AD_BLOCK = 'merchant_setup_block'
export const BANK_SETUP_AD_BLOCK = 'bank_setup_block'
export const VIEW_TRANSACTIONS_BLOCK = 'view_transactions_block'
export const VIEW_INVOICE_BLOCK = 'view_invoice_block'
export const INVOICE_INFO_CARD = 'invoice_info_card'
export const TRANSACTION_INFO_CARD = 'transaction_info_card'
export const CARRYOVER_TAX_BENEFITS_CARD = 'carryover_tax_benefits'
export const MYLO_AD_BLOCK = 'mylo_ad_block'
export const FOUND_AD_BLOCK = 'found_ad_block'
export const FORMATIONS_LEAD = "Formations"
export const NEWTEK_LEAD = "newtek"
export const AdvisoryMeetingURLLinkForLead = `${window.origin}/sfapi/schedule/index.php?WID=sfLeadId&EO=1&TID=1on1&EmailCampaignID= advisory-portal-banner-top&APSBJ=portal-advisory-banner-top`
export const AdvisoryMeetingURLLinkForContact = `${window.origin}/sfapi/schedule/index.php?WID=sfContactId&EO=1&TID=BNA&EmailCampaignID=advisory-portal-banner-top&APSBJ=portal-advisory-banner-top`
export const UPLOAD_1040_INFO_CARD = 'upload_1040_info_card' 
export const UPLOAD_1040_POPUP = 'upload_1040_popup' 
export const LAST_1040_POPUP_DATE = 'last_1040_popup_date'