export const REDIRECT_URL = {
    UAT: 'https://uat.1800accountant.com',
}

export const QUERY_SUBSTRING =
    'cbapi/app.php/plaid/bank_account/handle_redirect?'


export const PAGE_TYPE = {
    INITIAL_PAGE: 'initialPage',
    SUCCESS_PAGE: 'SuccessPage',
    MANUAL_BANKING_PAGE: 'manualBankingPage',
    VERIFICATION_PAGE: 'verificationPage',
    ERROR_PAGE: 'errorPage',
    SSN_VERIFY_PAGE: 'ssnVerifyPage',
}

export const  MetadataStatus =  {
    InstitutionNotFound: 'institution_not_found',
}

export const PLAID_STATUS =  ["ITEM_NOT_FOUND", "NO_ACCOUNTS", "ITEM_NOT_FOUND", "ITEM_NOT_SUPPORTED", "NO_AUTH_ACCOUNTS"]

export const ERROR_MESSAGES = {
    GENERIC_ERROR: 'An error occurred. Please try again.',
    FETCH_BANK_DETAILS_FAIL: 'Failed to fetch bank account, please refresh the page and try again',
    FETCH_COMPANY_DETAILS_FAIL: 'Failed to fetch company details, please reload the page and try again',
    SUBMIT_AGREEMENT_FAIL: 'Failed to submit agreement, please try again',
    ITEM_ERROR: 'The Item you requested cannot be found.',
    ITEM_LOGIN_REQUIRED: "the login details of this item have changed",
    BANK_RECONNECT_REQUIRED: 'Something went wrong, Bank account needs to be reconnected',
    GET_BANK_DETAILS_ERROR: 'Something went wrong, please connect your bank account again',
    GET_LINK_TOKEN_ERROR: 'Something went wrong. please reload the page and try again',
    GET_TOKEN_ERROR: 'Failed to fetch token, please reload the page and try again',
    FINISH_ONBOARDING_ERROR: 'Failed to finish onboarding, please try again',
    PLAID_AUTH_FAILED: 'Plaid authorization failed',
    FETCH_BUSINESS_LIST_FAIL: 'Company Details is not accessible.',
}