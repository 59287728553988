import {
    setUserData,
    setAccountsData,
    getInitData,
    setAccountantData
} from '../../services/accountService';
import { clearCache } from '../../services/aclService';
import { getResource } from '../../services/axiosService';
import { resetBankAccounts } from '../../services/bankAccountService';
import { getFromLocalStorage } from '../../services/localStorageService';
import { prepareUserRoles } from './auth';

export const SET_INIT_DATA = 'SET_INIT_DATA';

export interface AppDataAction {
    type: string;
    state: any;
}

export type Action = AppDataAction;

export const setInitData = (data: any): AppDataAction => ({
    type: SET_INIT_DATA,
    state: data,
});

export function loadPostAuthData(currentAccountId?: string) {
    return (dispatch: any) => {
        return Promise.all([
            getResource('api/user/current'),
            getResource('api/user/current/portal'),
        ]).then((res: any) => {
            setUserData(res[0]);
            setAccountsData(res[1], currentAccountId);
            const accountant = getFromLocalStorage('accountant');
            if(res[0].accountant_mode && !accountant) {
                return getResource('accountant_api/accountant/current')
            }
            return Promise.resolve(null);
        })
        .then((res: any) => {         
            if(res) {
                setAccountantData(res);
            }
            dispatch(setInitData(getInitData()));
            dispatch(prepareUserRoles());
            clearCache();
            resetBankAccounts();
        });
    };
}
